/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */

export default {
  heroContainer: {
    bg: `omegaLighter`,
    py: [4, 5],
    px: [4, 0]
  },
  insuranceContainer: {
    bg: `omegaLighter`,
    py: 4,
    px: [4, 0],
    borderTop: '1px solid #c4cfdf',
    borderBottom: '1px solid #c4cfdf'
  },
  contentTwoContainer: {
    bg: `omegaDarker`,
    borderRadius: `0`,
    py: [4, 5],
    px: [4, 0]
  },
  programs: {
    bg: `omegaLighter`,
    borderRadius: `0`,
    py: 5,
    px: [4, 0]
  },
  programsWrapper: {
    textAlign: `center`
  }
}
